@charset "utf-8";


$font-size-base: 0.9rem !default;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
// @mixin media-query($device) {
//     @media screen and (max-width: $device) {
//         @content;
//     }
// }



// Import partials from `sass_dir` (defaults to `_sass`)
// @import
//         "base",
//         "layout",
//         "syntax-highlighting"
// ;

@import "bootstrap", "alpertopia"