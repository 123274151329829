/* necessary edits for sticky footer */
html {
    position: relative;
    min-height: 100%;
}
body {
    /* Margin bottom by footer height */
    margin-bottom: 60px;
}
body.with-nav {
    padding-top: 5rem;
}

h1, h2 {
    scroll-margin-top: 4rem;
}

.jumbotron {
    border-radius: 0;
    .container {
        min-height: 250px;
    }
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 60px;
    line-height: 60px; /* Vertically center the text there */
    background-color: #f5f5f5;
}

/* correctly size svgs in navbar */
.navbar .navbar-nav-svg {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
}

.inline-icon {
    font-size: 1.4em !important;
    position: relative;
    top: 2px;
    margin-right: 5px;
}

.future-card {
    a, a:hover {
        color: #666;
    }

    .bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
        user-select: none;
    }
}

.resources ul {
    line-height: 10px;
}

.resources .list-inline-item {
    margin-right: 7px;
}

.resources a:hover {
    text-decoration: none;
    color: #333;
}

.resources li.resource-title {
    color: #aaa;
    width: 90px;
    text-align: right;
}

.publication-entry .publication-meta {
    color: #aaa;
    font-size: 0.9em;
}

/** post-specific styling */
article {
    ul li {
        margin-bottom: 0.5rem;
    }
}

/* enable collapse for recipe search results
 * specify at higher specificity to override bootstrap styling of card */
#recipe-results {
    display: none;

    &.show {
        display: flex;
    }
}

#recipe-list.card-columns {
    column-gap: 2rem;

    @include media-breakpoint-only(md) {
        column-count: 2;
    }
    @include media-breakpoint-only(sm) {
        column-count: 2;
    }

    /* enable accordion for collapsible list-groups;
     * specify at higher specificity to override bootstrap styling of list-group */
    .list-group {
        &.collapse {
            display: none;
        }

        &.collapse.show {
            display: flex;
        }
    }
}

div.receipe {
    ul > li {
        margin-bottom: 0.3rem;
    }

    ol > li {
        margin-bottom: 0.7rem;
    }
}
